
@tailwind base;
@tailwind components;
@tailwind utilities;


  .Frame {
font-weight: 700;
font-family: 'Montserrat Subrayada', sans-serif;
text-shadow: 0px 2px 15px;

  }